.mainContainer {
  background: #f2f4f8 !important;
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 12px !important;
}

.headerText {
  font-family: 'Work Sans';
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
}
.subHeaderText {
  font-family: 'Work Sans';
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.mainSection {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 12px !important;
  background: #f5f5f5 !important;
  padding: 16px;
  box-shadow: unset !important;
  margin: 1rem;
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.bodyContainer {
  width: 100%;
  padding: 1rem;
  gap: 16px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px !important;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: flex-start;
  box-shadow: unset !important;
}
